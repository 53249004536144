// generateImage.js
'use strict';
import getDrawImageCoordinate from '../resource/getDrawImageCoordinate.js';

/**
 * round and even value.
 * @param {number} value - to be rounded value.
 * @returns {number} return rounded and even value.
 */
const round = value => {
  const rounded = Math.round(value);

  if (rounded % 2 === 0) {
    return rounded;
  }

  return rounded - 1;
};

/**
 * Calculate size to ensure size is under maximum width or height.
 * @param {number} sourceWidth - source width.
 * @param {number} sourceHeight - source height.
 * @param {number} maximumWidth - maximum width.
 * @param {number} maximumHeight - maximum height.
 */
const getCoverSize = ({
  sourceWidth,
  sourceHeight,
  maximumWidth,
  maximumHeight,
}) => {
  if (sourceWidth <= maximumWidth && sourceHeight <= maximumHeight) {
    return {
      width: sourceWidth,
      height: sourceHeight,
    };
  }
  if (sourceWidth >= sourceHeight) {
    return {
      width: maximumWidth,
      height: (sourceHeight * maximumWidth) / sourceWidth,
    };
  }

  return {
    width: (sourceWidth * maximumHeight) / sourceHeight,
    height: maximumHeight,
  };
};

/**
 * Generate image blob
 * @param {HTMLVideoElement|HTMLCanvasElement|HTMLImageElement|SVGImageElement} {image} - image.
 * @param {number} {[ratioWidth = 16]} - ratio width.
 * @param {number} {[ratioHeight = 9]} - ratio hegith.
 * @param {number} {sourceWidth} - source width.
 * @param {number} {sourceHeight} - source hegith.
 * @param {string} {[outputFileType = 'image/jpg']} - output file type.
 * @param {number} {[outputQuality = 0.95]} - output file quality.
 * @param {number} {[maximumWidth = 4320]} - maximum width.
 * @param {number} {[maximumHeight = 4320]} - maximum height.
 * @return {Promise} will resolve with image blob.
 */
const generateImage = ({
  image,
  ratioWidth = 16,
  ratioHeight = 9,
  sourceWidth,
  sourceHeight,
  outputFileType = 'image/jpeg',
  outputQuality = 0.95,
  maximumWidth = 4320,
  maximumHeight = 4320,
}) =>
  new Promise(resolve => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const { x, y, targetWidth, targetHeight } = getDrawImageCoordinate({
      ratioWidth,
      ratioHeight,
      sourceWidth,
      sourceHeight,
    });
    const { width, height } = getCoverSize({
      sourceWidth: targetWidth,
      sourceHeight: targetHeight,
      maximumWidth,
      maximumHeight,
    });
    const roundedWidth = round(width);
    const roundedHeight = round(height);

    canvas.width = roundedWidth;
    canvas.height = roundedHeight;

    context.drawImage(
      image,
      x,
      y,
      targetWidth,
      targetHeight,
      0,
      0,
      roundedWidth,
      roundedHeight
    );

    canvas.toBlob(blob => resolve(blob), outputFileType, outputQuality);
  });

export default generateImage;
