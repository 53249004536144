// getDrawImageCoordinate.js
'use strict';

/**
 * @typedef Coordinate
 * @property {number} x - source x-axis coordinate.
 * @property {number} y - source y-axis coordinate.
 * @property {number} targetWidth - destination width.
 * @property {number} targetHeight - destination height.
 */

/**
 * Get canvas draw image coordinate.
 * @param {number} ratioWidth - ration width.
 * @param {number} ratioHeight - ration height.
 * @param {number} sourceWidth - source width.
 * @param {number} sourceHeight - source height.
 * @return {Coordinate}
 */
const getDrawImageCoordinate = ({
  ratioWidth,
  ratioHeight,
  sourceWidth,
  sourceHeight,
}) => {
  const targetAspectRatio = ratioWidth / ratioHeight;
  const sourceAspectRatio = sourceWidth / sourceHeight;

  if (sourceAspectRatio === targetAspectRatio) {
    return {
      x: 0,
      y: 0,
      targetWidth: sourceWidth,
      targetHeight: sourceHeight,
    };
  } else if (sourceAspectRatio > targetAspectRatio) {
    // source is wider
    const targetHeight = sourceHeight;
    const targetWidth = targetAspectRatio * targetHeight;
    const x = sourceWidth / 2 - targetWidth / 2;
    const y = 0;

    return {
      x,
      y,
      targetWidth,
      targetHeight,
    };
  } else {
    // source is taller
    const targetWidth = sourceWidth;
    const targetHeight = targetWidth / targetAspectRatio;
    const x = 0;
    const y = sourceHeight / 2 - targetHeight / 2;

    return {
      x,
      y,
      targetWidth,
      targetHeight,
    };
  }
};

export default getDrawImageCoordinate;
