// uploadJobReferences.js
'use strict';

const ID_REGEXP = /[\da-zA-Z-]+/; // TODO: remote config
const AVALIABLE_KEYS = [
  '__id__:media:xhr',
  '__id__:media:file',
  '__id__:media:resumableUrl',
  '__id__:thumbnail:xhr',
  '__id__:thumbnail:file',
  '__id__:trailer:xhr',
  '__id__:trailer:file',
];

const isAvaliableKey = ({ key }) => {
  return AVALIABLE_KEYS.includes(key.replace(ID_REGEXP, '__id__'));
};

export const referenceMap = new Map();

/**
 * return a specified element from Map.
 * @param {string} key - the key of the element to return from Map.
 * @return {any} the element associated with the specified key, or return `undefined` if the key can't not be found in the map.
 */
export const get = key => {
  if (isAvaliableKey({ key })) {
    return referenceMap.get(key);
  }

  throw new Error(`invalid key. current key is: ${key}.`);
};

/**
 * adds or updates element with specified key and a value to Map.
 * @param {string} key - the key of element to add to the Map.
 * @param {any} value - the value of element to add to the Map.
 */
export const set = (key, value) => {
  if (isAvaliableKey({ key })) {
    return referenceMap.set(key, value);
  }

  throw new Error(`invalid key. current key is: ${key}.`);
};

/**
 * delete all associated elements using id .
 * @param {Array} {ids} - ids for elemenet key to Map.
 */
export const deleteAll = ({ ids = [] }) =>
  ids.forEach(id =>
    AVALIABLE_KEYS.forEach(key => {
      const referenceKey = key.replace('__id__', id);
      referenceMap.delete(referenceKey);
    })
  );

export default {
  get,
  set,
  deleteAll,
};
