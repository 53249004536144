// setAssetPoster.js
// TODO: change to setAssetArtifact.js
'use strict';
import {
  MERGE_ASSET_DATA,
  REMOVE_ASSET_DATA,
  SET_OPERATION_DATA,
} from '../ActionTypes.js';
import {
  ARTIFACT_THUMBNAIL_FILE_SIZE_MAX,
  ARTIFACT_TRAILER_FILE_SIZE_MAX,
  TRAILER_VIDEO_LENGTH_SEC_MIN_MAX,
} from '../RemoteConfigKeys.js';

import pushToastr from '../action/pushToastr.js';

import getAssetData from '../selector/getAssetData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getOperationData from '../selector/getOperationData.js';

import { ArtifactType } from '../resource/assetConstants.js';
import { Status as UploadStatus } from '../resource/uploadConstants.js';
import formatBytes from '../resource/formatBytes.js';
import uploadJobReferences from '../resource/uploadJobReferences.js';
import getVideoDuration from '../resource/getVideoDuration.js';

/**
 * Set asset poster
 * @kind action
 * @param {string} {assetId} - asset id.
 * @param {File} {file} - file object. if file is empty means clear current poster file.
 * @param {ArtifactType} {artifactType} - artifact type.
 * @return {Promise} Action promise.
 */
const setAssetPoster =
  ({ assetId, file, artifactType } = {}) =>
  async (dispatch, getState) => {
    const currentArtifactObjectUrl = getAssetData(
      getState(),
      [assetId, artifactType],
      'objectUrl'
    );
    const assetIds =
      getOperationData(getState(), ['postDraft', artifactType], 'assetIds') ||
      [];
    // Clear current selected file only if user not select file.
    if (!file) {
      if (currentArtifactObjectUrl) {
        URL.revokeObjectURL(currentArtifactObjectUrl);
      }
      uploadJobReferences.set(`${assetId}:${artifactType}:file`, undefined);
      dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['postDraft', artifactType],
          data: {
            assetIds: [...new Set(assetIds.filter(id => assetId !== id))],
          },
        },
      });
      return dispatch({
        type: REMOVE_ASSET_DATA,
        payload: {
          selectPath: [assetId, artifactType],
        },
      });
    }
    // Update current selected file.
    const maxFileSize = getRemoteConfigData(
      getState(),
      ArtifactType.TRAILER === artifactType
        ? ARTIFACT_TRAILER_FILE_SIZE_MAX
        : ARTIFACT_THUMBNAIL_FILE_SIZE_MAX
    );
    const isValidSize = file.size <= maxFileSize;
    if (!isValidSize) {
      return dispatch(
        pushToastr({
          textKey: 'upload_file_error_hint',
          textKeyOption: {
            size: formatBytes({
              bytes: maxFileSize,
            }),
          },
          color: 'error',
        })
      );
    }
    if (ArtifactType.TRAILER === artifactType) {
      const durationRange = getRemoteConfigData(
        getState(),
        TRAILER_VIDEO_LENGTH_SEC_MIN_MAX
      );
      const [min, max] = durationRange;
      const duration = await getVideoDuration({ file });
      const isValidDuration = duration >= min && duration <= max;
      if (!isValidDuration) {
        return dispatch(
          pushToastr({
            textKey: 'alert_invalid_trailer',
            textKeyOption: {
              min,
              max,
            },
            color: 'error',
          })
        );
      }
    }
    if (currentArtifactObjectUrl) {
      URL.revokeObjectURL(currentArtifactObjectUrl);
    }
    uploadJobReferences.set(`${assetId}:${artifactType}:file`, file);
    dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['postDraft', artifactType],
        data: {
          assetIds: [...new Set([...assetIds, assetId])],
        },
      },
    });
    return dispatch({
      type: MERGE_ASSET_DATA,
      payload: {
        selectPath: [assetId, artifactType],
        data: {
          objectUrl: URL.createObjectURL(file),
          filename: file.name,
          status: UploadStatus.PENDING,
          progress: 0,
        },
      },
    });
  };

export default setAssetPoster;
