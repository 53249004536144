// addAsset.js
'use strict';
import { MERGE_ASSET_DATA } from '../ActionTypes.js';
import uploadJobReferences from '../resource/uploadJobReferences.js';

/**
 * Add asset
 * @kind action
 * @param {string} {assetId} - asset id.
 * @param {string} {uploadJobId} - upload job id.
 * @param {AssetPurpose} {purpose} - asset purpose.
 * @param {Blob} {file} - file.
 * @param {number|null} {duration} - duration.
 * @return {Promise} Action promise.
 */
const addAsset =
  ({ assetId, uploadJobId, purpose, file, duration }) =>
  async dispatch => {
    if (!file || !assetId || !purpose || !uploadJobId) {
      return dispatch({ type: '' });
    }
    uploadJobReferences.set(`${assetId}:media:file`, file);
    const objectUrl = URL.createObjectURL(file);
    return dispatch({
      type: MERGE_ASSET_DATA,
      payload: {
        selectPath: [assetId],
        data: {
          id: assetId,
          uploadJobId,
          objectUrl,
          contentType: file.type,
          contentLength: file.size,
          purpose,
          filename: file.name,
          duration,
        },
      },
    });
  };

export default addAsset;
