// unsubscribePresenceAsset.js
'use strict';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Unsubscribe presence asset
 * @kind action
 * @param {string} {assetId} - asset id.
 * @return {Promise} Action promise.
 */
const unsubscribePresenceAsset =
  ({ assetId }) =>
  async dispatch => {
    if (!assetId) {
      return dispatch({ type: '' });
    }
    return sendMessageToSW({
      type: MessageTypes.UNSUBSCRIBE_PRESENCE_ASSET_CHANNEL,
      payload: {
        assetId,
      },
    });
  };

export default unsubscribePresenceAsset;
