// setJoinAsset.js
'use strict';
import { v4 as uuidv4 } from 'uuid';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import { ASSET_FILE_SIZE_MAX } from '../RemoteConfigKeys.js';

import addUploadJob from '../action/addUploadJob.js';
import removeUploadJobs from '../action/removeUploadJobs.js';
import pushToastr from '../action/pushToastr.js';
import addAsset from '../action/addAsset.js';

import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { UploadJobType } from '../resource/uploadConstants.js';
import generateImage from '../resource/generateImage.js';

/**
 * Set join asset
 * @kind action
 * @param {File} {file} - selected file.
 * @param {AssetPurpose} {purpose} - asset purpose.
 * @return {Promise} Action promise.
 */
const setJoinAsset =
  ({ file, purpose }) =>
  async (dispatch, getState) => {
    const currentUploadJobId = getOperationData(
      getState(),
      ['join', 'draft', purpose],
      'uploadJobId'
    );
    if (currentUploadJobId) {
      dispatch(removeUploadJobs({ uploadJobIds: [currentUploadJobId] }));
    }
    if (!file) {
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['join', 'draft', purpose],
          data: {
            uploadJobId: undefined,
            isLoading: false,
          },
        },
      });
    }
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['join', 'draft', purpose],
        data: {
          isLoading: true,
        },
      },
    });
    const assetId = uuidv4();
    const uploadJobId = uuidv4();
    try {
      const maxFileSize = +getRemoteConfigData(getState(), ASSET_FILE_SIZE_MAX);
      if (file.size <= 0 || file.size > maxFileSize) {
        throw new Error('Invalid file.');
      }
      const processedFile = await generateCroppedImage({ file });
      dispatch(
        addAsset({
          assetId,
          uploadJobId,
          purpose,
          file: new File([processedFile], file.name, {
            type: processedFile.type,
          }),
        })
      );
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['join', 'draft', purpose],
          data: {
            isLoading: false,
            uploadJobId,
          },
        },
      });
      return dispatch(
        addUploadJob({
          uploadJobId,
          type: UploadJobType.KYC,
          assetIds: [assetId],
        })
      );
    } catch (error) {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['join', 'draft', purpose],
          data: {
            isLoading: false,
          },
        },
      });
      return dispatch(
        pushToastr({
          textKey: 'alert_incorrect_format',
          color: 'error',
        })
      );
    }
  };

/**
 * Generate cropped image.
 * @param {Blob} {file} - image file.
 * @param {number} {ratioWidth} - target ratio width.
 * @param {number} {ratioHeight} - target ratio height.
 * @return {Blob} - Return cropped image.
 */
const generateCroppedImage = async ({ file, ratioWidth, ratioHeight }) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const sourceObjectUrl = URL.createObjectURL(file);
    image.src = sourceObjectUrl;
    image.onerror = () => {
      URL.revokeObjectURL(sourceObjectUrl);
      return reject(new Error('alert_incorrect_format'));
    };
    image.onload = async () => {
      const { naturalWidth, naturalHeight } = image;
      const shouldUseSourceRatio = !ratioWidth || !ratioHeight;
      const blob = await generateImage({
        image,
        sourceWidth: naturalWidth,
        sourceHeight: naturalHeight,
        ratioWidth: shouldUseSourceRatio ? naturalWidth : ratioWidth,
        ratioHeight: shouldUseSourceRatio ? naturalHeight : ratioHeight,
      });
      URL.revokeObjectURL(sourceObjectUrl);
      return resolve(blob);
    };
  });
};

export default setJoinAsset;
