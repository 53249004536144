// formatBytes.js
// Ref: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
'use strict';

/**
 * Format bytes
 * @param {number} bytes - bytes.
 * @param {number} decimals - decimals.
 * @return {string} formatted bytes.
 */
const formatBytes = ({ bytes, decimals = 2 }) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (bytes === 0) return `0${sizes[0]}`;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

export default formatBytes;
