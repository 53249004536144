// getVideoDuration.js
'use strict';

/**
 * Get video duration.
 * @param {File} {file} - file.
 * @return {Promise} - Return video duration.
 */
const getVideoDuration = ({ file }) => {
  return new Promise(resolve => {
    const objectUrl = URL.createObjectURL(file);
    const element = document.createElement('video');
    element.src = objectUrl;
    element.onloadedmetadata = () => {
      const { duration } = element;
      URL.revokeObjectURL(objectUrl);
      return resolve(duration);
    };
    element.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      return resolve(null);
    };
  });
};

export default getVideoDuration;
