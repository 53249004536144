// removeUploadJobs.js
'use strict';
import {
  REMOVE_OPERATION_DATA,
  REMOVE_LIST_ITEMS,
  REMOVE_MESSAGES,
} from '../ActionTypes.js';

import removeAssets from '../action/removeAssets.js';
import unsubscribePresenceAsset from '../action/unsubscribePresenceAsset.js';
import processUploadJob from '../action/processUploadJob.js';

import getOperationData from '../selector/getOperationData.js';

import uploadJobReferences from '../resource/uploadJobReferences.js';

/**
 * Remove join job
 * @kind action
 * @param {array} {uploadJobIds} - upload job ids.
 * @return {Promise} Action promise.
 */
const removeUploadJobs =
  ({ uploadJobIds = [] } = {}) =>
  async (dispatch, getState) => {
    const ids = uploadJobIds.filter(uploadJobId => !!uploadJobId);
    dispatch({
      type: REMOVE_LIST_ITEMS,
      payload: {
        selectPath: ['uploadJob'],
        itemIds: ids,
      },
    });
    ids.forEach(uploadJobId => {
      const assetIds =
        getOperationData(getState(), ['uploadJob', uploadJobId], 'assetIds') ||
        [];
      assetIds.forEach(assetId => {
        const xhr = uploadJobReferences.get(`${assetId}:media:xhr`);
        if (xhr) {
          xhr.abort();
        }
        dispatch(
          removeAssets({
            assetIds,
          })
        );
        dispatch(unsubscribePresenceAsset({ assetId }));
      });
      const messageId = getOperationData(
        getState(),
        ['uploadJob', uploadJobId],
        'messageId'
      );
      if (messageId) {
        dispatch({
          type: REMOVE_MESSAGES,
          payload: {
            itemIds: [messageId],
          },
        });
      }
      return dispatch({
        type: REMOVE_OPERATION_DATA,
        payload: {
          selectPath: ['uploadJob', uploadJobId],
        },
      });
    });
    return dispatch(processUploadJob());
  };

export default removeUploadJobs;
