// removeAssets.js
'use strict';
import { REMOVE_ASSET_DATA } from '../ActionTypes.js';
import setAssetPoster from '../action/setAssetPoster.js';
import getAssetData from '../selector/getAssetData.js';
import uploadJobReferences from '../resource/uploadJobReferences.js';
import { ArtifactType } from '../resource/assetConstants.js';

/**
 * Remove assets
 * @kind action
 * @param {array} {assetIds} - to be removed asset ids.
 * @return {Promise} Action promise.
 */
const removeAssets =
  ({ assetIds = [] } = {}) =>
  async (dispatch, getState) => {
    assetIds.forEach(assetId => {
      uploadJobReferences.set(`${assetId}:media:file`, undefined);
      const objectUrl = getAssetData(getState(), [assetId], 'objectUrl');
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
      Object.values(ArtifactType).forEach(artifactType =>
        dispatch(setAssetPoster({ assetId, artifactType }))
      );
      return dispatch({
        type: REMOVE_ASSET_DATA,
        payload: {
          selectPath: [assetId],
        },
      });
    });
  };

export default removeAssets;
