// addUploadJob.js
'use strict';
import { MERGE_OPERATION_DATA, ADD_LIST_ITEMS } from '../ActionTypes.js';

import processUploadJob from '../action/processUploadJob.js';

import {
  UploadJobType,
  Priority as UploadPriority,
  Status as UploadStatus,
} from '../resource/uploadConstants.js';

/**
 * Add upload job
 * @kind action
 * @param {string} {uploadJobId} - upload job id.
 * @param {UploadJobType} {type} - upload job type.
 * @param {array} {assetIds} - asset ids.
 * @param {array} {artifactAssetIds} - asset ids which has artifacts.
 * @param {string} {messageId} - local message id.
 * @param {boolean} {isUpdateMessage} - true means update message.
 * @return {Promise} Action promise.
 */
const addUploadJob =
  ({
    uploadJobId,
    type,
    assetIds,
    artifactAssetIds,
    messageId,
    isUpdateMessage,
  } = {}) =>
  async dispatch => {
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['uploadJob', uploadJobId],
        data: {
          id: uploadJobId,
          type,
          status:
            // update status to upload.completed since clip's asset already uploaded to server.
            UploadJobType.CLIP === type
              ? UploadStatus.UPLOAD_COMPLETED
              : UploadStatus.PENDING,
          reason: undefined,
          priority: UploadPriority[type],
          assetIds,
          artifactAssetIds,
          messageId,
          isUpdateMessage,
        },
      },
    });
    dispatch({
      type: ADD_LIST_ITEMS,
      payload: {
        selectPath: ['uploadJob'],
        itemIds: [uploadJobId],
      },
    });
    switch (type) {
      case UploadJobType.ME:
      case UploadJobType.STORY:
      case UploadJobType.POST:
      case UploadJobType.KYC:
      case UploadJobType.CLIP:
      case UploadJobType.CHAT: {
        return dispatch(processUploadJob());
      }
      default: {
        return dispatch({ type: '' });
      }
    }
  };

export default addUploadJob;
